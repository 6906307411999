<template>
  <div class="student-apply-for">
    <el-form ref="postForm" label-width="auto" label-position="right" :model="postData" :rules="rules">
      <el-form-item label="学员信息" prop="drvStudentName">
        <el-input v-model="postData.drvStudentName" disabled></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="drvStudentIdentityCard">
        <el-input v-model="postData.drvStudentIdentityCard" disabled></el-input>
      </el-form-item>
      <el-form-item label="业务类型" prop="usableVehicleType">
        <el-input v-model="postData.usableVehicleType" disabled></el-input>
      </el-form-item>
      <el-form-item label="补偿科目" prop="compensateSub">
        <el-select v-model="postData.compensateSub">
          <el-option label="科目一" value="1"></el-option>
          <el-option label="科目二" value="2"></el-option>
          <el-option label="科目三" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="补偿凭证" prop="receiptPath">
        <el-upload class="avatar-uploader" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
          <img v-if="postData.receiptPath" :src="$dict.SETTING.API_URL + '/image/' + postData.receiptPath" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <div class="admin-dialog-btns">
        <el-button type="primary" @click="submitHandler()">确认</el-button>
        <el-button @click="$emit('end')">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {schStudentApplyCompensation, systemUploadImg} from '@/api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      rules: {
        policyContractType: [this.$rules.required], // 1.补考补偿 2.报名补偿
        compensateSub: [this.$rules.required],
        receiptPath: [this.$rules.required],
      },
      postData: {
        businessSn: '',
        policyContractType: 1, // 1.补考补偿 2.报名补偿
        compensateSub: '',
        receiptPath: '',
      },
    };
  },
  created() {
    this.postData.businessSn = this.$props.row.businessSn;
    this.postData.drvStudentName = this.$props.row.drvStudentName;
    this.postData.drvStudentIdentityCard = this.$props.row.drvStudentIdentityCard;
    this.postData.usableVehicleType = this.$props.row.usableVehicleType;
  },
  methods: {
    beforeAvatarUpload(file) {
      let formData = new FormData();
      formData.append('file', file);
      systemUploadImg(formData).then((res) => {
        if (res.data.code == 0) {
          this.postData.receiptPath = res.data.data.url;
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    deleteImgHandler() {
      this.postData.receiptPath = '';
    },

    submitHandler() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let postData = {};
          Object.assign(postData, this.postData);
          this.$utils.resolveRequest(schStudentApplyCompensation(postData), this);
        } else {
          this.$message.warning('请填写正确信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>